import "./articles.scss"
// import React from 'react';

// import lenka from './images/lenka.png'
// console.log(lenka)

export default function Articles() {

    return (
        <div className="container articles" id="articles">
            <br />
            <br />
            <div className="row articles-article">
                <h1>Úvod do světa malovaných kamínků</h1>
                <hr />
                <br />

                <div className="col-md-3 articles-article-image">
                    <img src="images/content/child-painting.webp" className="card-img-top" alt="lenka" />
                </div>
                <div className="col-md-9 articles-article-content">
                    <p>
                        Malované kamínky jsou populární formou umění a zábavy, která spojuje kreativitu s přírodou. Tento koníček získal na popularitě v posledních letech a stává se způsobem, jak se lidé mohou vyjádřit, relaxovat a sdílet radost s ostatními. Včetně dětí, které nacházejí radost jak v hledání, tak v malování těchto barevných kamínků, představuje malování kamínků jedinečnou příležitost k propojení s přírodou a k uvolnění duševních blokací skrze kreativní činnost.
                        <br />
                        <br />
                        Pro mnohé se malování kamínků stává meditativní praxí, která umožňuje únik od běžných stresů a povinností. Soustředění se na každý tah štětce nebo výběr barvy poskytuje prostor pro klid a uvolnění mysli, zatímco vizuální a taktický kontakt s kamínkem může působit uklidňujícím dojmem. Tato aktivita také stimuluje fantazii, protože umělci, ať už mladí či staří, mohou přetvářet obyčejné kameny na živé umělecké díla, což může být zdrojem obrovské radosti a hrdosti.
                        <br />
                        <br />
                        Kromě osobního potěšení a relaxace malování kamínků podporuje i sociální interakci a komunitní ducha. Lidé často sdílejí své kamínky veřejně jako formu umění "zapoj a pusť", což přináší nečekané momenty radosti náhodným nálezům. Tato praxe nejenže rozjasňuje dni cizích lidí, ale také vytváří pocit propojenosti a společenství mezi účastníky.
                        <br />
                        <br />
                        Ve výsledku malování kamínků poskytuje jednoduchou, ale hluboce obohacující činnost, která spojuje lidi různých věkových skupin a pozadí přes společnou lásku k umění, přírodě a sdílení.

                    </p>

                </div>
            </div>

            <br />
            <br />
            <div className="row articles-article">
                <h4>Proč začít s malováním kamínků</h4>
                <hr />
                <div className="col-md-3 articles-article-image">
                    <img src="images/content/child-painting-with-mather.webp" className="card-img-top" alt="lenka" />
                </div>
                <div className="col-md-9 articles-article-content">
                    <ul>
                        <li>
                            <b>Kreativita:</b> Malování vám umožní projevit svou kreativitu a experimentovat s různými barvami a technikami, poskytuje prostor pro vyjádření osobních emocí a myšlenek prostřednictvím vizuálních obrazů. Tato aktivita vám nejenže umožní zkoumat rozmanité stylistické přístupy, ale také nabízí možnost objevit nové materiály a směsi barev, které mohou obohatit vaše umělecké dílo. Experimentování s různými médii a aplikacemi vám může otevřít dveře k novým a unikátním formám uměleckého vyjádření, což vede k neustálému rozvoji vašich schopností a rozšíření vaší umělecké palety.
                            <br /><br />
                        </li>
                        <li>
                            <b>Relaxace:</b> Tento koníček může působit jako meditativní cvičení, které pomáhá snižovat stres a uvolňovat napětí, zvláště díky opakovaným, rytmickým pohybům štětce a zaměření na detail, což umožňuje vaší myslí odpočinout od každodenních starostí. Tento způsob uměleckého vyjádření nabízí prostor pro klid a soustředění, kde můžete na chvíli zapomenout na vnější tlaky a stresory. Když se vaše pozornost plně soustředí na proces tvorby, vaše mysl se přirozeně uvolňuje a dopřává vám pocit pokoje a spokojenosti. Tímto způsobem malování na kamínky přispívá k lepší duševní pohodě a může dokonce podporovat hlubší psychologickou rezilienci vůči stresu v běžném životě.
                            <br /><br />
                        </li>
                        <li>
                            <b>Darování radosti:</b> Malované kamínky často slouží jako malé dárky nebo překvapení pro náhodné nálezce, což vnáší do každodenního života překvapivý moment štěstí a objevu. Pro děti je zvlášť vzrušující najít nějaký umělecky zdobený kámen nebo vytvořit vlastní dílo, které mohou poté někde nechat pro dalšího náhodného nálezce, což nejen podporuje jejich tvořivost, ale také učí děti hodnotě sdílení a altruismu. Tato jedinečná forma uměleckého vyjádření a interakce nabízí jednoduchý, ale mocný způsob, jak rozšířit radost a pozitivitu ve světě. Děti se také mohou naučit o emocionálním významu darování a radosti z toho, že jejich malované kamínky mohou vykouzlit úsměv na tváři někoho jiného.
                        </li>
                    </ul>
                </div>
            </div>

            <br />
            <br />
            <div className="row articles-article">
                <h2>Techniky malování</h2>
                <hr />

                <div className="col-md-3 articles-article-image">
                    <img src="images/content/techniques2.webp" className="card-img-top" alt="lenka" />
                </div>

                <div className="col-md-9 articles-article-content">
                    <ul>
                        <li>
                            <b>Použití šablon:</b>&nbsp;
                            Šablony jsou ideální pro začátečníky, protože umožňují dosáhnout přesných a čistých linií bez potřeby pokročilých malířských dovedností. Můžete si koupit hotové šablony nebo si vytvořit vlastní z tuhého papíru nebo kartonu.
                            <br />
                            <b>Jak na to:</b> Přitiskněte šablonu na povrch kamene a použijte houbičku nebo štětec k nanášení barvy. Po aplikaci barvy šablonu opatrně sundejte a nechte barvu zaschnout.
                        </li>
                        <li>
                            <b>Pointilismus:</b>&nbsp;
                            Pointilismus je technika, kde jsou obrázky vytvářeny pomocí malých, jasně oddělených bodů různě barevných barev, které se spojí do celkového obrazu.
                            <br />
                            <b>Jak na to:</b> Pro tento styl budete potřebovat tenký štětec nebo konec špejle pro vytváření malých bodů. Pracujte trpělivě a přidávejte body vedle sebe, dokud nevytvoříte požadovaný obrazec nebo design.
                        </li>
                        <li>
                            <b>Svobodné malování rukou:</b>&nbsp;
                            Pro ty, kteří mají více zkušeností nebo chtějí experimentovat, je svobodné malování rukou ideální. Tato technika vám umožní projevit vaši kreativitu bez jakýchkoli omezení.
                            <b>Jak na to:</b> Použijte štětce různých velikostí pro dosažení různých úrovní detailu. Malování rukou vyžaduje pevnou ruku a někdy i několik vrstev barvy pro zvýraznění detailů.
                        </li>
                        <li>
                            <b>Technika mokré na mokré:</b>&nbsp;
                            Technika mokré na mokré umožňuje barvám přirozeně se mísit přímo na kamenu, což vytváří krásné gradienty a barevné přechody.
                            <b>Jak na to: </b> Naneste základní vrstvu barvy a než začne schnout, přidejte další barvu, kterou chcete smíchat. Použijte štětec k roztírání a míchání barev přímo na kameni.
                        </li>
                        <li>
                            <b>Dotting nástroje:</b>&nbsp;
                            Dotting nástroje jsou perfektní pro vytváření přesných a jednotných bodů, které jsou ideální pro detailní práci a jemné vzory.
                            <b>Jak na to:</b> Sada dotting nástrojů obvykle obsahuje různé velikosti konců, které se mohou používat pro vytváření různých velikostí bodů. Ponořte konec nástroje do barvy a aplikujte na kámen s rovnoměrným tlakem.
                        </li>
                    </ul>
                </div>
                
            </div>

            <div className="about-welcome-panel">

                {/* <br />
                        

                       

                        <br />
                        <h4>Techniky malování</h4>
                        <hr />

                        

                        <br />
                        <h4>Materiály pro malování</h4>
                        <hr />
                        <p>K malování na kamínky můžete použít akrylové barvy, které jsou ideální díky své odolnosti a živým barvám. Co se týče štětců, doporučuje se mít širokou škálu velikostí pro různé úrovně detailů. Kromě toho můžete použít:</p>

                        <ul>
                            <li>
                                <b>Fixy na kameny</b> Jsou praktické pro jemnější detaily a písmo.
                            </li>
                            <li>
                                <b>Glitry a laky</b> Pro dodání lesku a ochrany.
                            </li>
                        </ul>
                        <br />

                        <b>Ochrana malovaných kamínků</b>
                        <hr />
                        <p>Pro ochranu vašich děl je důležité aplikovat vrstvu laku. Lak zabezpečí, že barvy zůstanou živé a odolají povětrnostním vlivům. Existují různé typy laků, včetně matných nebo lesklých, takže si můžete vybrat podle preferovaného vzhledu.</p> */}
            </div>

        </div >
    );
};
